import React, { useEffect } from 'react';
import Breadcrumbs from "../breadcrumbs";
import Formulario from '../Contact'
import { graphql, useStaticQuery } from 'gatsby';
import { getMessageWhatsapp } from '../../helpers/helper.rendering';
import { connect } from "react-redux";
import { useLocation } from "@reach/router";

const Main = ({actions, development}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              global_whatsapp
              keys {
                captcha
              }
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    contact{
                        title
        
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact{
                            link
                            value
                        }
                    }
              }
          }
    }`)

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.includes('/contacto')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Contacto,VEN']
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Contacto"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Contacto") || null) : null
            }
        } else {
            return null
        }
    }

    return(
        <section class={`contact-main ${checkActionsTop() ? "no-space-top" :  ""}`}>
            <div class="container px-3">
                <div class="row center center align-items-center">
                <div className="col-12 mt-lg-5 mt-2">
                        <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Contacto", route:   "", location: ""},
                                ]}
                            />
                    </div>
                    <div class="col-12 ">
                        <div class="row content-main align-items-start">
                        <div className="col-12">
                                <h2 class="mb-lg-2">{realEstate.sections.contact.title}</h2>   
                        </div>
                        <div class="col-12 col-lg-5  text-right align-items-center">   
                                <div class="content-contact d-block mt-5 mb-5 mb-lg-0">
                                    {/* <a class="mailto mb-4 d-block" href="mailto:info@carlosbezruk.com.ar"><h5>info@carlosbezruk.com.ar</h5></a> */}
                                    <a class="mailto mb-4 d-block hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}><h5>{realEstate.global_email}</h5></a>
                                    <div class="whatsapp my-3">
                                        <h5>Whatsapp</h5>
                                        {realEstate.branch_office.length > 0 &&
                                            realEstate.branch_office.map((branch,index) => (
                                            <div class="d-block flex-wrap mt-3 mt-lg-0">
                                            { branch.contact.whatsapp && <p className="d-none d-lg-flex  flex-wrap"> <div className="fixed-width"> {branch.name}</div> <a className='ms-1' href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>} 
                                            { branch.contact.whatsapp && <p className="d-flex d-lg-none  flex-wrap"> <div className="fixed-width">{branch.name} </div> <a className='ms-1' href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>}   
                                            </div>
                                            
                                            ))
                                        }  
                                    </div>
                                    <div class="d-none d-lg-block content-social mt-5 mb-4 col-12">
                                        <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook2"></i></a>
                                        <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram2"></i></a>
                                        <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin2"></i></a>
                                        <a target="_blank" href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+549" + realEstate.global_whatsapp} class={realEstate.global_whatsapp ? "d-inline" : 'd-none'}><i class="icon-whatsapp"></i></a>
                                    </div>
                                    <div className='d-none d-lg-block content-bottom col-12'>
                                        {realEstate.branch_office.length > 0 &&
                                            realEstate.branch_office.map((branch,index) => (
                                                <p class="text-footer mb-4" key={index}>
                                                    {branch.address} <br/>
                                                    {branch.city} 
                                                    {branch.region.length > 0 ? ` - ${branch.region}` : ''}<br/>
                                                    {branch.contact.phone ? branch.contact.phone.map((phone, index) => (
                                                        <p key={index} className={phone.length === 0 ? 'd-none' : ''}>Tel. {phone}</p>)) : ""}
                                                </p>))}
                                    </div>
                                </div>
                                    
                                
                            </div>
                            <div class="col-12 col-lg-6 offset-lg-1">
                                <Formulario from={'contact'} />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <section className="contact-main">
        //     <div className="container-fluid">
        //         <div className="row height">
        //             <div className="col-12 d-flex align-items-center">
        //                 <div className="row mt-4 pt-lg-0 pt-5 content-main">
        //                     <div className="col-12">
        //                         <Breadcrumbs
        //                             props={[
        //                             { name: realEstate.name, route: "/", location: "" },
        //                             { name: "Contacto", route:   "", location: ""},
        //                             ]}
        //                         />
        //                     </div>
        //                     <div className="col-12">
        //                         <h1>CONTACTO</h1>
        //                     </div>
        //                     <div className="col-lg-7 order-2 order-lg-1">
        //                         <Formulario from={'contact'} />
        //                     </div>
        //                     <div className="col-lg-3 offset-lg-1 text-center contact-nav d-lg-flex d-none align-items-center order-1 order-lg-2">
        //                         <div className="center-contact">
        //                             <div class="item-contact">
        //                                 <h5>{realEstate.sections.footer.title_contact}</h5>
        //                                 <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
        //                             </div>
        //                             <div className="max-heigth-scroll">
        //                                 {realEstate.branch_office.map((item,index) => (
        //                                     <div class="item-contact">
        //                                         <h5>{realEstate.sections.footer.title_office}{item.name}</h5>
        //                                         <p >{item.address}</p>
        //                                         <p >{item.city}</p>
        //                                         <p >{item.region}</p>
        //                                         {item.contact.phone && item.contact.phone.map(phone => (
        //                                             <p >Teléfono {phone}</p>
        //                                         ))}
        //                                         <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp { item.contact.whatsapp }</p>
        //                                         <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p>
        //                                     </div>
        //                                 ))} 
        //                             </div>
        //                             <div class="item-contact">
        //                                 <h5 className='mb-3'>{realEstate.sections.footer.title_social}</h5> 
        //                                 <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
        //                                 <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
        //                                 <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
        //                                 <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
        //                                 <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
} 

export default connect(state => ({
    actions: state.actions.actions,
}),null)(Main);